import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import Features from "../../components/Features/Features";
import Footer from "../../components/footer/Footer";
import FunFact2 from "../../components/FunFact2/FunFact2";
import Hero3 from "../../components/hero3/hero3";
import Navbar from "../../components/Navbar/Navbar";
import Scrollbar from "../../components/scrollbar/scrollbar";
import ServiceSection from "../../components/ServiceSection/ServiceSection";

import Logo from "../../images/logo.png";

const HomePage3 = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Topenář Luže - Domů",
    description:
      "Domovská stránka Topenář Luže. Jsem certifikovaný topenář s více než 25 lety zkušeností. Poskytuji služby v oblasti topenářství pro Vás.",
    url: "https://topenarluze.cz",
  };

  return (
    <Fragment>
      <Helmet>
        <title>Domovská stránka - Topenář Luže</title>
        <meta
          name="description"
          content="Instalace a opravy kotlů, podlahového topení a tepelných čerpadel v Luži a okolí."
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Navbar topbarBlock={"wpo-header-style-2"} Logo={Logo} />
      <Hero3 />
      <Features fClass={"wpo-features-section-s2"} />
      <ServiceSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default HomePage3;
