import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import PageTitle from "../../components/pagetitle/PageTitle";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Contactpage from "../../components/Contactpage/Contactpage";

const ContactPage = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    name: "Kontakt - Topenář Luže",
    description:
      "Kontaktujte Topenář Luže pro instalaci radiátorů, podlahového topení a dalších služeb.",
    url: "https://topenarluze.cz/kontakt",
  };

  return (
    <Fragment>
      <Helmet>
        <title>Kontakt - Topenář Luže</title>
        <meta
          name="description"
          content="Kontakt pro instalace a opravy topných systémů v Luži a okolí."
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Navbar />
      <PageTitle pageTitle={"Kontakt"} pagesub={"Kontakt"} />
      <Contactpage />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ContactPage;
