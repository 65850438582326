import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import PageTitle from "../../components/pagetitle/PageTitle";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import ServiceSection from "../../components/ServiceSection/ServiceSection";

const ServicePage = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Service",
    name: "Služby - Topenář Luže",
    description:
      "Prohlédněte si mé služby, včetně montáže kotlů, podlahového topení a tepelných čerpadel",
    provider: {
      "@type": "LocalBusiness",
      name: "Topenář Luže",
      url: "https://topenarluze.cz",
    },
  };

  return (
    <Fragment>
      <Helmet>
        <title>Služby - Topenář Luže</title>
        <meta
          name="description"
          content="Prohlédněte si mé služby, včetně montáže kotlů, podlahového topení a tepelných čerpadel"
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Navbar />
      <PageTitle pageTitle={"Služby"} pagesub={"Služby"} />
      <ServiceSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ServicePage;
